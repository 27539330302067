<template>
    <div class="service-table">
        <sn-table
            ref="tableGroup"
            :snTable="tableGroupAttributes.snTable"
            :commonMethods.sync="commonMethods"
        >
        </sn-table>
    </div>
</template>

<script>
import {commonData} from "./commonData";
import commonMethods from "./commonMethods";
import { mapActions } from 'vuex'
export default {
    props:{
        lxid:String
    },
    data(){
        return {
            tableGroupAttributes:commonData,
            commonMethods,
            params:{
                id:'',
                pageindex:1,
                pagesize:20
            }
        }
    },
    watch:{
        lxid(){
            this.getTableData();
        },
    },
    mounted(){
        this.getTableData();
    },
    methods:{
        ...mapActions([
            'getServiceFault'
        ]),
        async getTableData(){
            this.tableGroupAttributes.snTable.table.loading = true;
            const params = {
                ...this.params,
                id:this.lxid,
            }
            const res = await this.getServiceFault(params);
            console.log('getServiceFault-------->>>>>>>>>>>..............',res);
            this.tableGroupAttributes.snTable.table.data = res.DATA||[];
            this.tableGroupAttributes.snPage.count = Number(res.TOTAL) || 0;
            this.tableGroupAttributes.loading = false;
        },
        handleChange(type, value) {
            console.log("type", type, "value", value);
            const [v,k] = value;

            switch (type) {
                case 'searchBtn':
                    this.getTableData();
                    break;
                case 'search':
                    this.params[k] = v;
                    console.log(this.params);
                    this.getTableData();
                    break;
                case "columnSee":
                    this.columnSee (v);
                    break;
                case 'buttonExport':
                    this.buttonExport();
                    break;
                default:
                    break;
            }
        },
    },

}
</script>
<style lang="scss">
.service-table{
    .el-table{
        .el-table__header-wrapper{
            table{
                thead{
                    tr{
                        th{
                            background: #eeeff3;
                        }
                    }
                }
            }
        }
        .el-table__body-wrapper{
            tbody{
                tr{
                    background: #fff;
                }
            }
        }
    }
}
</style>