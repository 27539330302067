<template>
    <div class="service-monitor">
        <sn-bread :itemName="itemName" :breadlist="breadlist"></sn-bread>
        <div class="state-sum">
            {{COUNT>0?`外接服务共${COUNT}个${ZC>0?`,其中正常${ZC}个,正常率为${ZCV}%`:``}${YC>0?`,其中异常${YC}个,异常率为${YCV}%`:``}`:'暂无外接服务'}}。
        </div>
        <div class="state-content">
            <ul>
                <li v-for="(item,i) in JCZDLIST" :key="i" @click="changeLxid(item)" :class="{active:item.ID === lxid}">
                    <span class="tag">{{item.ZDMC}}</span>
                    <div class="outer" :id="`chart${i+1}`">
                    </div>
                    <div class="inner" :style="{backgroundImage:`url(${require(`@image/dataService_management/equip/${item.ZDMC}.png`)})`}">
                    </div>
                    <span class="box">
                        <span class="flex-box">
                            {{item.JRJG||"正常"}}
                        </span>
                    </span>
                </li>
            </ul>
            <service-table class="right-box" :lxid="lxid">
            </service-table>
        </div>
    </div>
</template>
<script>
import ServiceTable from "./service_table.vue";
import { mapGetters, mapActions } from 'vuex';
import * as TYPES from "@store/modules/mutation-type.js";
export default {
    data() {
        return {
            itemName:'服务监控',
            breadlist: [
                {
                    path: '',
                    name: '数据管理与接入'
                }
            ],
			COUNT: 0,//数量
			ZC: 0,//正常
			ZCV: 0.0,//正常(百分比)
			YC: 0,//异常
			YCV: 0.0,//异常(百分比)
            JCZDLIST:[],
            lxid:"",
            chartArr:[],
        }
    },
    components: {
        ServiceTable
    },
    computed: {
    },
    methods: {
        ...mapActions([
            'getServiceTypeList',
            'getDevRunningStatusEx',
            "getServiceFault",
        ]),
        draw(){
            this.JCZDLIST.forEach((ele,i)=>{
                const str = `chart${i+1}`;
                const dom = document.getElementById(str);
                console.log('dom===---->',dom,str);
                
                const temp = this.$echarts.init(dom);
                let arr = [];
                if(ele.JRJG === '异常'){
                    arr = [0,1];
                }
                else{
                    arr = [1,0];
                }
                const option = this.getOption(arr);
                temp.setOption(option);
            });
        },
        getOption(list = [0,0]){
            const base = 30;
            const grow = 20;
            const sum = (list[0]+list[1])||1;
            const color = ['#ff9065','#2dc393',];
            const num1 = +(grow*list[1]/sum).toFixed()+base;
            const radiuList1 = [`${base}%`,num1+'%'];
            const num2 = num1 + (+(grow*list[0]/sum).toFixed());
            const radiuList2 = [radiuList1[1],num2+'%'];
            console.log(radiuList1,radiuList2);
            const series = [];
            if(list[1]){
                series.push(createSeries({radius:radiuList1,color:color[0],value:list[1],z:5,name:'异常'}));
            }
            if(list[0]){
                series.push(createSeries({radius:radiuList2,color:color[1],value:list[0],z:1,name:'正常'}));
            }
            const option = {
                backgroundColor: 'transparent',
                series
            };
            return option;
            function createSeries({radius,color,value,z,name}){
                return {
                    type: 'pie',
                    radius,
                    center: ['50%', '50%'],
                    z,
                    startAngle: 190,
                    data: [{
                            hoverOffset: 1,
                            value,
                            name: '',
                            itemStyle: {
                                color,
                            },
                            label: {
                                normal: {
                                    show: false,
                                    formatter: `{r|${name}{c}个\n({d}%)}`,
                                    rich: {
                                        r: {
                                            color,
                                            fontWeight: 'bold',
                                            fontSize: 14,
                                        },
                                    },
                                    position: 'outside',
                                },

                            },
                            labelLine: {
                                normal: {
                                    lineStyle: {
                                        width: 0
                                    }
                                }
                            },
                            hoverAnimation: false,
                        },
                    ]
                }
            }
        },
        async getData(){
            const res = await this.getServiceTypeList();
            console.log('getServiceTypeList----->',res);
            if(res &&res.TOTALOBJ&& typeof res.TOTALOBJ === 'object'){
                const temp = res.TOTALOBJ;
                for (const key in temp) {
                    const ele = temp[key];
                    this[key] = ele;
                }
            }
            if(res &&Array.isArray(res.JCZDLIST)){
                this.JCZDLIST = res.JCZDLIST;
                setTimeout( ()=>{
                     this.draw();
                },0)
            }
        },
        // 监听全面检测
        handleStaticCircleClick() {
            this.isStaticCircleClick = true;
        },
        // 全面检测完成
        handleCheckFinashed(val) {
            if (val) {
                this.isStaticCircleClick = false;
                this.isCheckFinished = true;
            } else {
                this.isCheckFinished = false;
            }
        },

        // 地图展示
        showPointByMap() {
            this.isSbqdMapShow = true;
        },
        // 返回设备清单列表
        BackToSbqdTable() {
            this.isSbqdMapShow = false;
        },
        async getTableData() {
            let res = await this.getSelectData({
                _cols: [], //字段集
                _keyword: "", //查询关键字
                _serarchCols: "", //关键字字段集合
                _conditionList: [], //过滤条件集合
                _dicOrderby: {},
                _pageSize: null, //每页最大记录数
                _pageIndex: null, //页索引
                _returnSum: true, //是否返回记录总数
                _needVersion: false, //是否分版本查询
                _searchFormal: false, //T：查询最近的正式版本数据；F：查询最近的数据
                itemid: "116" //业务编号
            });
            console.log(3333, res);
            if (res.data && res.data.length > 0) {
                this.mapPoint = [];
                res.data.forEach(item => {
                    if (item.AZDJD && item.AZDWD) {
                        this.mapPoint.push(item);
                    }
                });
            } else {
                this.mapPoint = [];
            }
        },
        gotoDetail(val){
            this.isDetailShow = val;
        },
        changeLxid(item){
            if(this.lxid === item.ID){
                this.lxid = "";
            }else{
                this.lxid = item.ID;
            }
        },
    },
    created() {
        // this.GetDevRunningStatus();
    },
    mounted(){
        // this.getTableData();
        this.getData();
    },
}
</script>
<style scoped lang="scss">
$outer:#2ec392;
$inner:#ff9066;
.service-monitor {
    height: 100%;
    overflow: auto;
    .state-sum{
        height: 110px;
        line-height: 110px;
        font-size: 25px;
        box-sizing: border-box;
        padding: 0 45px;
        color:#2c4375;
        background: #fff;
        margin-top: 20px;
        margin-bottom: 20px;
        font-weight: 600;
        .checkBtn{
            display: inline-block;
            background: rgb(42, 99, 213);
            color:#fff;
            height: 30px;
            line-height: 30px;
            font-size: 16px;
            border-radius: 15px;
            padding:0 25px;
            letter-spacing: 3px;
        }
    }
    .state-content{
        display: flex;
        >ul{
            flex:0 0 320px;
            >li{
                height: 260px;
                position: relative;
                background: #fff;
                span.tag{
                    position: absolute;
                    left: 0;
                    top: 0;
                    background: #bfbfbf;
                    height: 30px;
                    line-height: 30px;
                    padding-left: 15px;
                    padding-right: 25px;
                    border-bottom-right-radius: 30px;
                    &::before{
                        width: 320px;
                        height: 3px;
                        background: #bfbfbf;
                        position: absolute;
                        top: 0;
                        left: 0;
                        display: block;
                        content:"";
                    }
                }
                .inner{
                    border-radius: 50%;
                    padding:39px;
                    position: absolute;
                    left: 50%;
                    top: 50%;
                    transform: translate(-50%, -50%);
                    z-index: 10;
                    background-repeat:no-repeat;
                    background-position: center;
                    background-size: 40px;
                }
                .outer{
                    position: absolute;
                    left: 0;
                    top: 0;
                    right: 0;
                    bottom: 0;                    
                }
                .box{
                    position: absolute;
                    bottom: 20px;
                    width:100%;
                    box-sizing: border-box;
                    padding:0 25px;
                    text-align: center;
                    .flex-box{
                        display: inline-block;
                        padding:0 15px;
                    }
                }
                &.active{
                    .tag{
                        background: rgb(42, 99, 213);
                        color:#fff;
                        &::before{
                            background: rgb(42, 99, 213);
                        }
                    }
                }
            }
        }
        .right-box{
            flex:1 1 0;
            margin-left: 20px;
        }
    }
}
</style>
